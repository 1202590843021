import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import SessionContext from '../../../contexts/SessionContext';
import reqwest from 'reqwest';
import settings from '../../../settings';

const PrivateRoute = () => {
  const location = useLocation();
  const { sessionData, setSessionData } = useContext(SessionContext);

  const checkSession = () => {
    try {
      if (!sessionData || !sessionData.eMail) {
        reqwest({
          method: 'GET',
          url: settings.general.sessionUser,
        }).then((sessionUser) => {
          if (sessionUser && sessionUser.eMail) {
            setSessionData(sessionUser);
          } else {
            setSessionData(undefined);
          }
        });

        return;
      }

      reqwest({
        method: 'GET',
        url: settings.general.checkToken,
        data: {
          Token: sessionData.token,
        },
      }).then((tokenIsValid) => {
        if (!tokenIsValid) {
          setSessionData(undefined);
        }
      });
    } catch (e) {
      setSessionData(undefined);
    }
  };

  useEffect(() => {
    checkSession();
  }, [location]);

  if (!sessionData) {
    return <Navigate to="login" />;
  }
  return <Outlet />;
};

export default PrivateRoute;
