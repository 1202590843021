import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlagCheckered,
  faSearch,
  faCommentAltDots,
  faCar,
  faStar,
} from "@fortawesome/pro-light-svg-icons";
import reqwest from "reqwest";
import { useTranslation } from "react-i18next";
import settings from "../../../settings";
import DataContainer from "../../../logic/dataContainer";
import SessionContext from "../../../contexts/SessionContext";

const DashboardLinksMenu = () => {
  const { t } = useTranslation();
  const { sessionData } = useContext(SessionContext);
  const [ridesLength, setRidesLength] = useState(0);
  const [messageLength, setMessageLength] = useState(0);
  const [ratingsLength, setRatingsLength] = useState(0);

  useEffect(() => {
    reqwest({
      method: "GET",
      url: settings.booking.getAllUnaccepted,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
      },
    }).then((response) => {
      const tcRides = new DataContainer(response);
      setRidesLength(tcRides.containers.length);
    });

    reqwest({
      method: "GET",
      url: settings.message.getUnreadMessages,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
      },
    }).then((response) => {
      // Todo: Nur Anzahl der ungelesenen Nachrichten benötigt
      const tcMessages = new DataContainer(response);
      setMessageLength(tcMessages.containers.length);
    });

    reqwest({
      method: "GET",
      url: settings.booking.getUnratedBookings,
      data: {
        Token: sessionData?.token,
        UserGuid: sessionData?.userGuid,
      },
    }).then((response) => {
      const tcRatings = new DataContainer(response);

      setRatingsLength(
        tcRatings.get("Entry", "SearchAllResult") === "Entry not found" // SearchAllResult - This is not needed anymore
          ? 0
          : tcRatings.containers.length
      );
    });
  }, []);

  return (
    <div className="DashboardLinks">
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "DashboardLinks-navLink DashboardLinks-navLink--active"
            : "DashboardLinks-navLink"
        }
        to="/dashboard"
        end
      >
        <FontAwesomeIcon
          icon={faFlagCheckered}
          className="DashboardLink-Icon"
        />
        <span>{t("dashboard.menu.dashboard")}</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "DashboardLinks-navLink DashboardLinks-navLink--active"
            : "DashboardLinks-navLink"
        }
        to="/dashboard/rides"
      >
        {ridesLength > 0 && (
          <div className="DashboardLinks-flag">{ridesLength}</div>
        )}
        <FontAwesomeIcon icon={faCar} className="DashboardLink-Icon" />
        <span>{t("dashboard.menu.your_rides")}</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "DashboardLinks-navLink DashboardLinks-navLink--active"
            : "DashboardLinks-navLink"
        }
        to="/dashboard/requests"
      >
        <FontAwesomeIcon icon={faSearch} className="DashboardLink-Icon" />
        <span>{t("dashboard.menu.your_requests")}</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "DashboardLinks-navLink DashboardLinks-navLink--active"
            : "DashboardLinks-navLink"
        }
        to="/dashboard/message-center"
      >
        {messageLength > 0 && (
          <div className="DashboardLinks-flag">{messageLength} </div>
        )}
        <FontAwesomeIcon
          icon={faCommentAltDots}
          className="DashboardLink-Icon"
        />
        <span>{t("dashboard.menu.your_messages")}</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "DashboardLinks-navLink DashboardLinks-navLink--active"
            : "DashboardLinks-navLink"
        }
        to="/dashboard/ratings"
      >
        {ratingsLength > 0 && (
          <div className="DashboardLinks-flag">{ratingsLength}</div>
        )}
        <FontAwesomeIcon icon={faStar} className="DashboardLink-Icon" />
        <span>{t("dashboard.menu.your_rated_bookings")}</span>
      </NavLink>
    </div>
  );
};

DashboardLinksMenu.propTypes = {};

DashboardLinksMenu.defaultProps = {};

export default DashboardLinksMenu;
