import React from "react";
import { PropTypes } from "prop-types";
import { ReactComponent as DetailsBottomSVG } from "./details_bottom.svg";
import { ReactComponent as DetailsBottomHighSVG } from "./details_bottom_high.svg";

const DetailsBottom = ({ open, menu }) => {
  if (menu) {
    return (
      <div className={`DetailsBottom ${open ? "open" : "closed"}`}>
        <DetailsBottomHighSVG />
      </div>
    );
  }
  return (
    <div className={`DetailsBottom ${open ? "open" : "closed"}`}>
      <DetailsBottomSVG />
    </div>
  );
};
DetailsBottom.propTypes = {
  open: PropTypes.bool,
  menu: PropTypes.bool,
};

DetailsBottom.defaultProps = {
  open: true,
  menu: false,
};
export default DetailsBottom;
