/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import {
  faMars,
  faTransgenderAlt,
  faVenus,
} from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import { ReactComponent as IconSmokingYes } from '../../images/icons/smoking-yes.svg';
import { ReactComponent as IconSmokingNo } from '../../images/icons/smoking-no.svg';
import { ReactComponent as IconSmokingSometimes } from '../../images/icons/smoking-sometimes.svg';
import { ReactComponent as IconConversationsYes } from '../../images/icons/conversations-yes.svg';
import { ReactComponent as IconConversationsNo } from '../../images/icons/conversations-no.svg';
import { ReactComponent as IconConversationsSometimes } from '../../images/icons/conversations-sometimes.svg';
import { ReactComponent as IconMusicYes } from '../../images/icons/music-yes.svg';
import { ReactComponent as IconMusicNo } from '../../images/icons/music-no.svg';
import { ReactComponent as IconMusicSometimes } from '../../images/icons/music-sometimes.svg';
import { ReactComponent as IconColleague } from '../../images/icons/colleague.svg';
import { ReactComponent as SuitCase } from '../../images/icons/luggage/luggage_suitcase.svg';
import { ReactComponent as SuitCaseTwo } from '../../images/icons/luggage/luggage_2_suitcases.svg';
import { ReactComponent as Handbag } from '../../images/icons/luggage/luggage_handbag.svg';
import CarData from '../../images/imageData/carData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle0,
  faCommentsDollar,
  faMoneyBillWaveAlt,
} from '@fortawesome/pro-solid-svg-icons';

const handleSplitCost = (splitCost) => {
  let value = i18next.t('newOffer.splitCosts.split');
  let icon = <FontAwesomeIcon icon={faCommentsDollar} />;
  switch (splitCost) {
    case 'split':
      icon = <FontAwesomeIcon icon={faCommentsDollar} />;
      value = i18next.t('newOffer.splitCosts.split');
      break;
    case 'fix':
      icon = <FontAwesomeIcon icon={faMoneyBillWaveAlt} />;
      value = i18next.t('newOffer.splitCosts.fix');
      break;
    case 'free':
      icon = <FontAwesomeIcon icon={faCircle0} />;
      value = i18next.t('newOffer.splitCosts.free');
      break;
    default:
      break;
  }
  return { value, icon };
};

const handleLuggage = (luggage) => {
  let value = i18next.t('newSearch.suitcase');
  let icon = <SuitCase />;
  switch (luggage) {
    case 'suitcase':
      icon = <SuitCase />;
      value = i18next.t('newSearch.suitcase');
      break;
    case '2_suitcases':
      icon = <SuitCaseTwo />;
      value = i18next.t('newSearch.2_suitcases');
      break;
    case 'handbag':
      icon = <Handbag />;
      value = i18next.t('newSearch.handbag');
      break;
    default:
      break;
  }
  return { value, icon };
};

const handleCar = (carType) => {
  const value = i18next.t(
    `fields.car.carType.options.${carType || 'limousine'}`,
  );
  let icon = <CarData.car.Compact />;
  switch (carType) {
    case 'compact_car':
      icon = <CarData.car.Compact />;
      break;
    case 'limousine':
      icon = <CarData.car.Limo />;
      break;
    case 'convertible':
      icon = <CarData.car.Cabrio />;
      break;
    case 'station_wagon':
      icon = <CarData.car.Combi />;
      break;
    case 'suv':
      icon = <CarData.car.Suv />;
      break;
    case 'van':
      icon = <CarData.car.Van />;
      break;
    case 'bus':
      icon = <CarData.car.Bus />;
      break;
    default:
      icon = <CarData.car.Compact />;
      break;
  }
  return { value, icon };
};

const handleSex = (sex) => {
  let value = '';
  let icon = '';
  switch (sex) {
    case 'male':
      value = i18next.t('fields.user.sex.options.male');
      icon = faMars;
      break;
    case 'female':
      value = i18next.t('fields.user.sex.options.female');
      icon = faVenus;
      break;
    case 'nonBinary':
      value = i18next.t('fields.user.sex.options.nonBinary');
      icon = faTransgenderAlt;
      break;
    default:
      value = '';
      icon = undefined;
      break;
  }
  return { value, icon };
};

const handleSmoking = (smoking) => {
  let value = null;
  let icon = '';
  switch (smoking) {
    case 'yes':
      value = i18next.t('fields.user.smoking.options.yes');
      icon = IconSmokingYes;
      break;
    case 'no':
      value = i18next.t('fields.user.smoking.options.no');
      icon = IconSmokingNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.smoking.options.sometimes');
      icon = IconSmokingSometimes;
      break;
    default:
      value = i18next.t('fields.user.smoking.options.none');
      icon = IconSmokingSometimes;
      break;
  }
  return { value, icon };
};

const handleConversations = (conversations) => {
  let value = '';
  let icon = '';
  switch (conversations) {
    case 'yes':
      value = i18next.t('fields.user.conversations.options.yes');
      icon = IconConversationsYes;
      break;
    case 'no':
      value = i18next.t('fields.user.conversations.options.no');
      icon = IconConversationsNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.conversations.options.sometimes');
      icon = IconConversationsSometimes;
      break;
    default:
      value = i18next.t('fields.user.conversations.options.none');
      icon = IconConversationsSometimes;
      break;
  }
  return { value, icon };
};

const handleMusic = (music) => {
  let value = '';
  let icon = '';
  switch (music) {
    case 'yes':
      value = i18next.t('fields.user.music.options.yes');
      icon = IconMusicYes;
      break;
    case 'no':
      value = i18next.t('fields.user.music.options.no');
      icon = IconMusicNo;
      break;
    case 'sometimes':
      value = i18next.t('fields.user.music.options.sometimes');
      icon = IconMusicSometimes;
      break;
    default:
      value = i18next.t('fields.user.music.options.none');
      icon = IconMusicYes;
      break;
  }
  return { value, icon };
};

const handleCompany = (company, companies) => {
  if (company && companies && companies[company]) {
    return { value: companies[company], icon: IconColleague };
  }
  if (companies) {
    return {
      value: i18next.t('fields.user.companies.options.none'),
      icon: IconColleague,
    };
  }
  return null;
};

export {
  handleSplitCost,
  handleLuggage,
  handleCar,
  handleSex,
  handleMusic,
  handleConversations,
  handleSmoking,
  handleCompany,
};
