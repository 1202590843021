/* eslint-disable import/no-anonymous-default-export */
import { ReactComponent as Babycarriage } from "../icons/extras/Babycarriage.svg";
import { ReactComponent as Wheelchair } from "../icons/extras/Wheelchair.svg";

export default {
  extras: {
    Babycarriage,
    Wheelchair,
  },
  extrasOptions: {
    babycarriageValue: "babycarriage",
    wheelchairValue: "wheelchair",
  },
};
