import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getUser from '../../actions/getUser';
import ProfileImage from '../ProfileImage';
import UserRating from '../shared/UserRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

const PublicUser = ({ userGuid }) => {
  const { t } = useTranslation();
  const [userDataContainer, setUserDataContainer] = useState(null);

  useEffect(() => {
    getUser(userGuid).then((publicUser) => {
      setUserDataContainer(publicUser);
    });
  }, [userGuid]);

  if (!userGuid || !userDataContainer) {
    return <div className="PublicUser" />;
  }

  return (
    <div className="PublicUser">
      <div className="ProfileImage-Wrapper">
        <ProfileImage
          guid={userDataContainer.get('PersonalInfo', 'ProfileImage')}
        />
      </div>
      <div className="PublicUser-user">
        <h2 className="PublicUser-name">
          {userDataContainer.get('RequiredData', 'Username')}
        </h2>
        <UserRating guid={userDataContainer.get('Basic', 'Guid')} />
      </div>
      <div className="PublicUser-Icon">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};

PublicUser.propTypes = {
  userGuid: PropTypes.string.isRequired,
};

PublicUser.defaultProps = {};

export default PublicUser;
