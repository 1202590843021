import React from 'react';
import i18next from 'i18next';

export function groupsToSelectOptions(groups) {
  const selectGroups = groups.map((g) => {
    return {
      value: `${g.id}`,
      label: `${g.name}`,
      labelComponent: (
        <>
          <img src={g.image} alt={g.shortName} style={{ height: '1em' }} />{' '}
          {g.name}
        </>
      ),
    };
  });

  selectGroups.push({
    value: null,
    label: i18next.t('selectgroups.noselect.label'),
    labelComponent: i18next.t('selectgroups.noselect.labelcomponent'),
  });
  return selectGroups;
}

export function getSelectedGroupIdByType(memberType, userGroups) {
  if (userGroups) {
    const userGroup = userGroups.find((g) => g.GroupType === memberType);
    if (userGroup) {
      return userGroup.GroupId;
    }
  }
  return null;
}

export function updateUserGroups(id, groupType, userGroups) {
  if (userGroups) {
    const userGroup = userGroups.find((g) => g.GroupType === groupType);
    userGroup.GroupId = id;
    return userGroups;
  }
  return [{ GroupId: id, GroupType: groupType }];
}

export function getUserGroup(userGroups, memberType, members) {
  const id = getSelectedGroupIdByType(memberType, userGroups);
  return members.find((g) => g.id === id);
}
