import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({
  onClick,
  disabled,
  loading,
  shape,
  type,
  className,
  href,
  target,
  to,
  icon,
  children,
  ...rest
}) => {
  const handleClick = () => {
    if (typeof onClick === "function" && !disabled && !loading) {
      onClick();
    }
  };

  const classNames = `Button ${shape} ${
    disabled ? "disabled" : ""
  } ${className}`;

  // Render exterhal Link
  if (href) {
    return (
      <a
        className={classNames}
        disabled={disabled}
        target={target}
        rel="noopener noreferrer"
        href={href}
        {...rest}
      >
        {icon && <FontAwesomeIcon icon={icon} className="Button-icon" />}
        {children}
      </a>
    );
  }

  // Render internal Link
  if (to) {
    return (
      <Link className={classNames} to={to} {...rest} type={type}>
        {icon && <FontAwesomeIcon icon={icon} className="Button-icon" />}
        {children}
      </Link>
    );
  }

  // Render functional Button (default)
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      {loading && (
        <div className="Button-loader">
          <div />
          <div />
          <div />
        </div>
      )}
      <div className="Button-content">
        {icon && <FontAwesomeIcon icon={icon} className="Button-icon" />}
        {children}
      </div>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.shape()),
  }),
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  shape: PropTypes.oneOf([
    "default",
    "round",
    "action",
    "warning",
    "text",
    "smallText",
  ]),
};

Button.defaultProps = {
  className: "",
  type: "button",
  shape: "default",
  disabled: false,
  loading: false,
  to: null,
  href: null,
  target: "_blank",
  onClick: null,
  icon: null,
};

export default Button;
