import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faCommentSmile, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../components/Loader';
import ProfileImage from '../../components/ProfileImage';
import RenderHTML from '../../components/RenderHTML';
import UserRating from '../../components/shared/UserRating';
import {
  handleCompany,
  handleConversations,
  handleMusic,
  handleSex,
  handleSmoking,
} from '../../constants/profileValues';
import useDerivative from '../../hooks/useDerivative';
import { getUserGroup } from '../../logic/groups';
import { GetMembersByType } from '../../api/derivate';
import { GetPublicUserDC, useUserAPI } from '../../api/user';

const ProfileModal = ({ guid }) => {
  const { t } = useTranslation();
  const { companies, memberType } = useDerivative();
  const {
    result: dataContainer,
    loading,
    error,
  } = useUserAPI({
    apiFunction: GetPublicUserDC,
    data: guid,
  });

  if (loading) {
    return (
      <div className="ProfileModal">
        <div className="Profile-Header">
          <div className="ProfileImage-Wrapper">
            <ProfileImage huge guid={null} />
          </div>
          <div>
            <h2 className="Profile-name">{t('profileModal.loading.title')}</h2>
            <div className="Profile-user" />
          </div>
        </div>
        <div className="Profile-content-wrapper">
          <div />
          <div>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="ProfileModal">
        <div className="Profile-Header">
          <div className="ProfileImage-Wrapper">
            <ProfileImage huge guid={null} />
          </div>
          <div>
            <h2 className="Profile-name">{t('profileModal.error.title')}</h2>
            <div className="Profile-user" />
          </div>
        </div>
        <div className="Profile-content-wrapper" />
      </div>
    );
  }

  const genderValues = handleSex(dataContainer.get('PersonalInfo', 'Sex'));

  const smokingValues = handleSmoking(
    dataContainer.get('PersonalInfo', 'Smoking'),
  );
  const conversationsValues = handleConversations(
    dataContainer.get('PersonalInfo', 'Conversations'),
  );
  const musicValues = handleMusic(dataContainer.get('PersonalInfo', 'Music'));
  const companyValues = handleCompany(
    dataContainer.get('PersonalInfo', 'Company'),
    companies,
  );

  return (
    <div className="ProfileModal">
      <div className="Profile-Header">
        <div className="ProfileImage-Wrapper">
          <ProfileImage
            huge
            guid={dataContainer.get('PersonalInfo', 'ProfileImage')}
          />
        </div>
        <div>
          <h2 className="Profile-name">
            {dataContainer.get('RequiredData', 'Username')}
          </h2>
          <div className="Profile-user">
            <UserRating guid={dataContainer.get('Basic', 'Guid')} />
          </div>
        </div>
      </div>
      <div className="Profile-content-wrapper">
        <div />
        <div className="Profile-content">
          <div className="Profile-preferences">
            {companyValues && (
              <div className="Profile-preference">
                <companyValues.icon className="Profile-preference-icon" />
                <div className="Profile-preference-text">
                  {companyValues.value}
                </div>
              </div>
            )}
            <div className="Profile-preference">
              {genderValues.icon && (
                <FontAwesomeIcon
                  icon={genderValues.icon}
                  className="Profile-preference-icon"
                />
              )}
              <div className="Profile-preference-text">
                {genderValues.value}
              </div>
            </div>
          </div>
          <br />
          <h2>{t('profileModal.values.title')}</h2>
          <div className="Profile-preferences">
            {memberType && (
              <Group
                memberType={memberType}
                userGroups={dataContainer.get('PersonalInfo', 'Groups')}
              />
            )}
            <div className="Profile-preference">
              <smokingValues.icon className="Profile-preference-icon" />
              <div className="Profile-preference-text">
                {smokingValues.value}
              </div>
            </div>
            <div className="Profile-preference">
              <conversationsValues.icon className="Profile-preference-icon" />
              <div className="Profile-preference-text">
                {conversationsValues.value}
              </div>
            </div>
            <div className="Profile-preference">
              <musicValues.icon className="Profile-preference-icon" />
              <div className="Profile-preference-text">{musicValues.value}</div>
            </div>

            {dataContainer.get('PersonalInfo', 'About') && (
              <div className="Profile-preference">
                <FontAwesomeIcon
                  icon={faUser}
                  className="Profile-preference-icon"
                />
                <div className="Profile-preference-text">
                  {t('publicuser.preference.text')}
                  <br />
                  <RenderHTML
                    html={dataContainer.get('PersonalInfo', 'About')}
                  />
                </div>
              </div>
            )}
            {dataContainer.get('PersonalInfo', 'FavoriteTopics') && (
              <div className="Profile-preference">
                <FontAwesomeIcon
                  icon={faCommentSmile}
                  className="Profile-preference-icon"
                />
                <div className="Profile-preference-text">
                  {t('publicuser.preference.favoritetopics.text')}
                  <br />
                  <RenderHTML
                    html={dataContainer.get('PersonalInfo', 'FavoriteTopics')}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileModal.propTypes = {
  guid: PropTypes.string.isRequired,
};

ProfileModal.defaultProps = {};

export default ProfileModal;

const Group = ({ memberType, userGroups }) => {
  const [members, setMembers] = useState(null);
  useEffect(() => {
    GetMembersByType(memberType).then((res) => {
      setMembers(res);
    });
  }, [memberType]);

  if (members) {
    const g = getUserGroup(userGroups, memberType, members);
    if (g) {
      return (
        <div className="Profile-preference">
          <img src={g.image} alt={g.shortName} />
          <div className="Profile-preference-text">{g.name}</div>
        </div>
      );
    }
  }
  return null;
};
