import { useEffect, useState } from 'react';
import settings from '../settings';
import reqwest from 'reqwest';

// API

export function useDerivateAPI({ apiFunction, method, url, data }) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    if (apiFunction) {
      apiFunction(data).then((res) => {
        if (res) {
          setResult(res);
        } else {
          setError('Error in request');
        }
        setLoading(false);
      });
    } else if (url) {
      reqwest({ url, method, data })
        .then((result) => {
          if (result) {
            setResult(result);
          } else {
            setError('Error in request');
          }
          setLoading(false);
        })
        .fail(() => {
          setError('Error in request');
          setLoading(false);
        });
    }
  }, [apiFunction, method, url, data]);

  return { result, loading, error };
}

// Derivat
export async function DerivateSettings() {
  return reqwest(`${settings.derivate.derivateSettings}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return null;
      }
    })
    .fail(() => {
      return null;
    });
}

export async function SpecificDerivate(className) {
  return reqwest(`${settings.derivate.specificDerivate}?className=${className}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return {};
      }
    })
    .fail(() => {
      return {};
    });
}

export async function PreviewDerivate(className) {
  return reqwest(`${settings.preview.specificDerivate}?className=${className}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return {};
      }
    })
    .fail(() => {
      return {};
    });
}

export function DerivateList() {
  return reqwest(`${settings.derivate.derivateList}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

// POIs

export async function GetPoisForDerivate(appguid) {
  return reqwest(`${settings.derivate.getPoisForDerivate}?appguid=${appguid}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

export async function GetPoisByType(type) {
  return reqwest(`${settings.derivate.getPoisByType}?type=${type}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

/***  Members ***/

export async function GetMembersByType(type) {
  return reqwest(`${settings.derivate.getMembersByType}?type=${type}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

/**** Events ***/
export async function GetEventsByTags(tags) {
  return reqwest(
    `${settings.derivate.getEventsByTags}?${tags.map((t) => 'tags=' + t)}`,
  )
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

export async function GetEventsByMember(memberList) {
  return reqwest(
    `${settings.derivate.getEventsByMember}?memberList=${memberList}`,
  )
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}

export async function GetEventsByType(type) {
  return reqwest(`${settings.derivate.getEventsByType}?type=${type}`)
    .then((result) => {
      if (result) {
        return result;
      } else {
        return [];
      }
    })
    .fail(() => {
      return [];
    });
}
