import React, { useContext, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import reqwest from "reqwest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faTrashAlt,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import settings from "../../settings";
import TripConnection from "../TripConnection";
import SessionContext from "../../contexts/SessionContext";
import DataContainer from "../../logic/dataContainer";
import Weekdays from "../Weekdays";
import { useNavigate } from "react-router";

const Card = ({ entry, active, searchStart, searchDest, onClick }) => {
  const { sessionData } = useContext(SessionContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bookingStates, setBookingStates] = useState();

  const loadBookingsForTrip = async () => {
    const bs = {
      all: 0,
      accepted: 0,
      requested: 0,
      declined: 0,
      deleted: 0,
    };
    return reqwest({
      url: settings.booking.getAllBookingsForParameter,
      method: "GET",
      data: {
        Token: sessionData.token,
        keyName: "TripGuid",
        keyValue: entry.Guid,
      },
    })
      .then((response) => {
        new DataContainer(response)
          .getMany("Entry")
          .filter((b) => {
            return b.Guid;
          })
          .forEach((booking) => {
            // eslint-disable-next-line no-plusplus
            bs[booking.Status]++;
            // eslint-disable-next-line no-plusplus
            bs.all++;
          });
        setBookingStates(bs);
      })
      .fail(() => {
        setBookingStates(bs);
      });
  };

  useEffect(() => {
    if (entry.OfferState === "myRide") {
      loadBookingsForTrip();
    } else {
      setBookingStates();
    }
  }, [entry]);

  const handleClick = (_) => {
    if (onClick && typeof onClick === "function") {
      onClick(entry.Guid);
    }
  };

  const handleKeyDown = (ev) => {
    ev.stopPropagation();
    if (ev.keyCode === 13 && ev.target.id === "cardContent") {
      onClick(entry.Guid);
    }
  };

  const deleteEntry = (evt) => {
    evt.stopPropagation();
    swal({
      title: t("deleteTrip.messages.confirm.title"),
      text: t("deleteTrip.messages.confirm.description"),
      buttons: [
        t("deleteTrip.messages.confirm.buttons.cancel"),
        t("deleteTrip.messages.confirm.buttons.confirm"),
      ],
      icon: "warning",
      dangerMode: "true",
    }).then((confirmed) => {
      if (confirmed) {
        reqwest({
          method: "POST",
          url:
            entry.Typ !== "Request"
              ? settings.offer.delete
              : settings.request.delete,
          data: {
            Token: sessionData?.token,
            Guid: entry.Guid,
          },
        })
          .then(() => {
            swal({
              title: t("deleteTrip.messages.success.title"),
              text: t("deleteTrip.messages.success.description"),
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          })
          .fail(() => {
            swal({
              title: t("deleteTrip.messages.error.title"),
              text: t("deleteTrip.messages.error.description"),
              icon: "error",
            });
          });
      }
    });
  };

  const toEntry = (evt) => {
    evt.stopPropagation();
    navigate(`${entry.Typ === "Offer" ? "/offer/" : "/request/"}${entry.Guid}`);
  };

  if (
    entry.TimeSlots &&
    entry.TimeSlots !== "" &&
    !Array.isArray(entry.TimeSlots)
  ) {
    entry.TimeSlots = entry.TimeSlots.replace(/"/g, "'").replace(/'/g, '"');
    entry.TimeSlots = entry.TimeSlots !== "" ? JSON.parse(entry.TimeSlots) : "";
  }

  const userDeleted = entry.UserGuid === "-1";

  return (
    <div className="Card">
      <div
        className={`Card-content ${active ? "active" : ""} ${
          userDeleted ? "deletedDriver" : ""
        }`}
        id={entry.Guid}
        role="button"
        tabIndex="0"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {(entry.OfferState || entry.UserGuid === sessionData?.userGuid) && (
          <div className="Card-header-section">
            {bookingStates?.all ? (
              <>
                {bookingStates.accepted > 0 && (
                  <div className="Card-header-label accepted">
                    {bookingStates.accepted} {t(`dashboard.rideStates.booked`)}
                  </div>
                )}
                {bookingStates.requested > 0 && (
                  <div className="Card-header-label requested">
                    {bookingStates.requested}{" "}
                    {t(`dashboard.rideStates.requested`)}
                  </div>
                )}
                {bookingStates.declined > 0 &&
                  bookingStates.all === bookingStates.declined && (
                    <div className="Card-header-label declined">
                      {bookingStates.declined}{" "}
                      {t(`dashboard.rideStates.declined`)}
                    </div>
                  )}
              </>
            ) : (
              <>
                {entry.OfferState ? (
                  <div className={`Card-header-label ${entry.OfferState}`}>
                    {t(`dashboard.rideStates.${entry.OfferState}`)}
                  </div>
                ) : (
                  <div className="Card-header-label" />
                )}
              </>
            )}
            {entry.Guid && entry.UserGuid === sessionData?.userGuid && (
              <div className="Card-header-buttons">
                <button
                  className="Card-header-buttons-edit"
                  onClick={toEntry}
                  title={t("editTrip.button.title")}
                  type="button"
                >
                  <FontAwesomeIcon icon={faPenSquare} />
                </button>
                <button
                  className="Card-header-buttons-trash"
                  onClick={deleteEntry}
                  title={t("delteTrip.button.title")}
                  type="button"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            )}
          </div>
        )}
        <div className="Card-main-section">
          <TripConnection
            entry={entry}
            searchStart={searchStart}
            searchDest={searchDest}
          />
          {active ? (
            <a
              className="Card-main-chevron"
              href="#rideDetails"
              aria-label={t("card.detailbutton.aria-label")}
              onClick={(ev) => ev.stopPropagation()}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          ) : (
            <div className="Card-main-chevron">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </div>
        <div className="Card-footer-section">
          {entry.Weekdays && (
            <div className="Card-footer-dates">
              <Weekdays data={entry} />
            </div>
          )}
          {entry.Typ === "Request" && (
            <div className="Card-footer-label">
              <div className="Card-footer-label-inner">
                {t("card.footer.label.request")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  entry: PropTypes.shape({
    Guid: PropTypes.string,
    UserGuid: PropTypes.string,
    Typ: PropTypes.string,
    DepartureTime: PropTypes.string,
    searchStart: PropTypes.string,
    ArrivalTime: PropTypes.string,
    MapsDestination: PropTypes.string,
    Weekdays: PropTypes.arrayOf(PropTypes.shape()),
    TimeSlots: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        departureTime: PropTypes.string,
        arrivalTime: PropTypes.string,
      })
    ).isRequired,
    MapsWaypoints: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        stopover: PropTypes.bool,
      })
    ).isRequired,
    WeekdayCycle: PropTypes.string,
    Date: PropTypes.string,
    OfferState: PropTypes.string,
    legs: PropTypes.string,
    StartDistance: PropTypes.number,
    EndDistance: PropTypes.number,
    IsDirect: PropTypes.bool,
    CycleOptions: PropTypes.string,
    GroupPreference: PropTypes.string,
  }),
  searchStart: PropTypes.string,
  searchDest: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Card.defaultProps = {
  entry: {
    IsDirect: true,
  },
  active: false,
  searchStart: undefined,
  searchDest: undefined,
};

export default Card;
