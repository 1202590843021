import React from 'react';

export const searchFilterContextDefault = {
  radius: '10',
  sex: '',
  luggage: '',
  babycarriage: '',
  wheelchair: '',
  freeSeats: '',
  carType: '',
  fuel: '',
  conversations: '',
  smoking: '',
  music: '',
  travelType: '',
  memberPreference: 'all',
};

const SearchFilterContext = React.createContext({
  filterOptions: {},
  setFilterOptions: () => {},
});

export default SearchFilterContext;
