import '../assets/cryptojs';
import React, { useEffect, useState } from 'react';
import { initDB } from 'react-indexed-db-hook';
import App from './App';
import SessionContext from './contexts/SessionContext';
import useSession from './hooks/useSession';
import useDerivative from './hooks/useDerivative';
import addMatomoScript from './logic/matomo';
import styleOverride from './logic/styleOverride';
import faviconOverride from './logic/faviconOverride';
import { getPreviewClassName, isPreview } from './logic/helpers';
import { LanguageProvider } from './contexts/LanguageContext';
import Loader from './components/Loader';
import { loadGoogleScript } from './hooks/useGoogleMaps';
import { AppProvider } from './contexts/AppContext';
import { DerivateSettings, PreviewDerivate } from './api/derivate';

/** Setup Local DB für caching profile images */
initDB({
  name: 'PendlerPortal',
  version: 1,
  objectStoresMeta: [
    {
      store: 'images',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'guid', keypath: 'guid', options: { unique: true } },
      ],
    },
  ],
});

// eslint-disable-next-line react/prop-types
const Bootstrap = () => {
  const { setToken, userdata, setUserData, clearSession } = useSession();
  const derivative = useDerivative();

  const [dbReady, setDbReady] = useState(false);
  const [derivativeReady, setDerivativeReady] = useState(null);
  const [googleReady, setGoogleReady] = useState(false);
  const [sessionData, setSessionData] = useState(userdata);
  const setSessionDataAndStore = (sd) => {
    if (sd) {
      setUserData(sd);
      setToken(sd?.token);
    } else {
      clearSession();
    }
    setSessionData(sd);
  };

  const initDerivative = async (d) => {
    if (d.matomoSiteId && process.env.REACT_APP_ENV !== 'development') {
      addMatomoScript(d.matomoSiteId);
    }

    if (d.className) {
      /* Set class name. This is used by the CSS variables */
      document.querySelector('html').classList.add(d.className);
      /* Set website title */
      document.title = d.name ? d.name : document.title;
      /* Override website favicon */
      faviconOverride(d.className);
      /* Override css variables if available in cdn folder */
      styleOverride(d.className);
    }
    setDerivativeReady(d);
  };

  useEffect(() => {
    setDbReady(true);
    loadGoogleScript('de', setGoogleReady);
    /** Setup Derivatives */
    const preview = isPreview();
    if (preview) {
      const className = getPreviewClassName();
      PreviewDerivate(className).then((result) => {
        if (result) {
          localStorage.setItem('derivative', JSON.stringify(result));
          initDerivative(result);
        } else {
          initDerivative(derivative);
        }
      });
    } else if (process.env.REACT_APP_ENV === 'development') {
      initDerivative(derivative);
    } else {
      DerivateSettings().then((result) => {
        if (result) {
          const matchUrls = (url) => url === window.location.host;
          if (!result.urls.some(matchUrls)) {
            window.location.replace('https://pendlerportal.de'); // redirect if subdomain does not exist.
          } else {
            localStorage.setItem('derivative', JSON.stringify(result));
            initDerivative(result);
          }
        }
      });
    }
  }, []);

  if (!dbReady || !derivativeReady || !googleReady) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <SessionContext.Provider
      value={{
        sessionData,
        setSessionData: setSessionDataAndStore,
      }}
    >
      <LanguageProvider
        className={derivativeReady.className}
        languages={derivativeReady.languages || ['de']}
        loadCustomTexts
      >
        <AppProvider>
          <App />
        </AppProvider>
      </LanguageProvider>
    </SessionContext.Provider>
  );
};

export default Bootstrap;
