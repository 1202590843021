import { ReactComponent as Compact } from "../icons/car/Kompaktklasse.svg";
import { ReactComponent as Cabrio } from "../icons/car/Cabrio.svg";
import { ReactComponent as Combi } from "../icons/car/Kombi.svg";
import { ReactComponent as Limo } from "../icons/car/Limousine.svg";
import { ReactComponent as Van } from "../icons/car/Minivan.svg";
import { ReactComponent as Suv } from "../icons/car/SUV.svg";
import { ReactComponent as Bus } from "../icons/car/Bus.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  car: {
    Suv,
    Compact,
    Combi,
    Limo,
    Van,
    Cabrio,
    Bus,
  },
};
