import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import deTranslations from '../../lang/de';
// import deChTranslations from '../../lang/de-CH'; // for testing
import enTranslations from '../../lang/en';
import { isPreview } from '../logic/helpers';
import useGoogleMaps from '../hooks/useGoogleMaps';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de-ch';
import 'moment/locale/de';
import 'moment/locale/fr';

export const LanguageActionTypes = {
  SET_READY: 'setReady',
  SET_SELECTED_LANGUAGE: 'setSelectedLanguage',
  SET_AVAILABLE: 'setAvailable',
};

const LanguageContext = createContext('de');
const LanguageDispatchContext = createContext(null);

export function LanguageProvider({
  className = 'pp40',
  languages = ['de'],
  loadCustomTexts = false,
  children,
}) {
  moment.locale('de');
  const selected = localStorage.getItem('selected_language') || languages[0];
  const { googleReady, setGoogleLanguage } = useGoogleMaps();

  function languageReducer(prevState, action) {
    switch (action.type) {
      case LanguageActionTypes.SET_READY: {
        return { ...prevState, ready: action.value };
      }
      case LanguageActionTypes.SET_SELECTED_LANGUAGE: {
        localStorage.setItem('selected_language', action.value);
        i18next.changeLanguage(action.value);
        setGoogleLanguage(action.value);
        moment.locale(action.value);
        return { ...prevState, selected: action.value };
      }
      case LanguageActionTypes.SET_AVAILABLE: {
        localStorage.setItem('portal_languages', action.value.join(','));
        return { ...prevState, available: action.value };
      }
      default: {
        throw Error(`Unknown action: ${action.type}`);
      }
    }
  }

  const [state, dispatch] = useReducer(languageReducer, {
    ready: false,
    selected,
    languages,
  });

  async function loadAndOverrideCustomTexts() {
    return Promise.all(
      languages.map((language) => {
        const url = isPreview()
          ? `https://conf.pendlerportal.de/files/${className}/${language}-translations.json`
          : `https://cdn.pendlerportal.de/${className}/${language}-translations.json`;
        return fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json().then((json) => {
                i18next.addResourceBundle(
                  language,
                  className,
                  json,
                  true,
                  false,
                );
                return Promise.resolve();
              });
            }
            return Promise.resolve();
          })
          .catch((error) => {
            return Promise.resolve();
          });
      }),
    ).then(() => {
      i18next.setDefaultNamespace(className);
      return Promise.resolve();
    });
  }

  // init langauges with i18next
  useEffect(() => {
    const resources = {
      de: deTranslations,
      // 'de-CH': deChTranslations, // for testing
      en: enTranslations,
    };
    const callback = async () => {
      if (loadCustomTexts) {
        await loadAndOverrideCustomTexts();
      }
      dispatch({ type: LanguageActionTypes.SET_READY, value: true });
    };
    i18next
      .use(initReactI18next) // passes i18n down to react-i18next
      .init(
        {
          defaultNS: 'translation',
          fallbackNS: 'translation',
          resources,
          lng: selected,
          fallbackLng: 'de',
          interpolation: {
            escapeValue: false, // react already safes from xss
          },
        },
        callback,
      );

    setGoogleLanguage(selected);
  }, [loadCustomTexts, selected]);

  return (
    <LanguageContext.Provider value={state}>
      <LanguageDispatchContext.Provider value={dispatch}>
        {!state.ready ||
          (!googleReady && (
            <>
              Not ready: <br />
              ready{state.ready ? 'ture' : 'false'}
              googleReady{googleReady ? 'ture' : 'false'}
            </>
          ))}
        {state.ready && googleReady && children}
      </LanguageDispatchContext.Provider>
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  className: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
  loadCustomTexts: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export function useLanguage() {
  return useContext(LanguageContext);
}

export function useLanguageDispatch() {
  return useContext(LanguageDispatchContext);
}
