import { useEffect, useState } from 'react';
import settings from '../settings';
import reqwest from 'reqwest';
import DataContainer from '../logic/dataContainer';

// API

export function useUserAPI({ apiFunction, method, url, data }) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    if (apiFunction) {
      apiFunction(data).then((res) => {
        if (res) {
          setResult(res);
        } else {
          setError('Error in request');
        }
        setLoading(false);
      });
    } else if (url) {
      reqwest({ url, method, data })
        .then((result) => {
          if (result) {
            setResult(result);
          } else {
            setError('Error in request');
          }
          setLoading(false);
        })
        .fail(() => {
          setError('Error in request');
          setLoading(false);
        });
    }
  }, [apiFunction, method, url, data]);

  return { result, loading, error };
}

export async function GetPublicUserDC(userGuid) {
  return reqwest({
    method: 'GET',
    url: settings.user.getPublic,
    data: {
      Guid: userGuid,
    },
  })
    .then((response) => {
      if (response) {
        return new DataContainer(response);
      } else {
        return null;
      }
    })
    .fail(() => {
      return null;
    });
}
