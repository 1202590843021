import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router';
import PrivateRoute from './components/Layout/PrivateRoute';
import PointPush from './components/PointPush';
import { PushProvider } from './contexts/PushContext';
import SearchContext from './contexts/SearchContext';
import SearchFilterContext, {
  searchFilterContextDefault,
} from './contexts/SearchFilterContext';
import useDerivative from './hooks/useDerivative';
import { useSearchHelper, useWidgetParams } from './logic/searchHelper';
import Achievements from './routes/Achievements';
import AboutPP from './routes/AboutPP';
import Agb from './routes/Agb';
import Contact from './routes/Contact';
import Dashboard from './routes/Dashboard';
import DeleteProfile from './routes/DeleteProfile';
import DerivativeSelector from './routes/DerivativeSelector';
import ForgotPassword from './routes/ForgotPassword';
import Help from './routes/Help';
import HomePage from './routes/HomePage';
import Imprint from './routes/Imprint';
import Login from './routes/Login';
import Logout from './routes/Logout';
import MessageCenter from './routes/MessageCenter';
import NewOffer from './routes/NewOffer';
import NewSearch from './routes/NewSearch';
import Press from './routes/Press';
import Privacy from './routes/Privacy';
import Profile from './routes/Profile';
import ProfileEdit from './routes/ProfileEdit';
import Ratings from './routes/Ratings';
import Requests from './routes/Requests';
import ResetPassword from './routes/ResetPassword';
import Rides from './routes/Rides';
import Search from './routes/Search';
import SignUp from './routes/SignUp';
import VerifyEmail from './routes/VerifyEmail';
import PublicTrip from './routes/PublicTrip';

const App = () => {
  const currentDerivative = useDerivative();
  const { searchOptions, updateSearchOptions } = useSearchHelper();
  const [filterOptions, setFilterOptions] = useState(
    searchFilterContextDefault,
  );
  const widgetParams = useWidgetParams();

  useEffect(() => {
    if (widgetParams) {
      updateSearchOptions(widgetParams);
      if (widgetParams.depg && widgetParams.destg)
        window.location.href = '/search';
    }
  }, [widgetParams]);

  return (
    <SearchFilterContext.Provider value={{ filterOptions, setFilterOptions }}>
      <PushProvider>
        {currentDerivative.achievements && <PointPush />}
        <SearchContext.Provider value={{ searchOptions, updateSearchOptions }}>
          <Router>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              {currentDerivative.loginTypes &&
              currentDerivative.loginTypes.forceSSO ? (
                <Route path="/sign-up" element={<Login />} />
              ) : (
                <Route path="/sign-up" element={<SignUp />} />
              )}
              <Route path="/login" element={<Login />} />
              <Route path="/delete" element={<DeleteProfile />} />
              <Route path="/verify-email/:token" element={<VerifyEmail />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/logout" element={<Logout />} />
              <Route path="/search" element={<Search />} />
              <Route path="/trip/:type/:guid" element={<PublicTrip />} />
              {currentDerivative.achievements && (
                <Route
                  path="/dashboard/achievements"
                  element={<Achievements />}
                />
              )}
              <Route
                path="/search/:origin?/:destination?"
                element={<Search />}
              />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/help" element={<Help />} />
              <Route path="/about" element={<AboutPP />} />
              <Route path="/agb" element={<Agb />} />
              <Route path="/press" element={<Press />} />
              <Route path="/contact" element={<Contact />} />
              {process.env.REACT_APP_ENV === 'development' && (
                <>
                  <Route path="/derivatives" element={<DerivativeSelector />} />
                </>
              )}
              <Route element={<PrivateRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route path="/profile-edit/*" element={<ProfileEdit />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard/ratings/:guid?" element={<Ratings />} />
                <Route path="/dashboard/rides/:guid?" element={<Rides />} />
                <Route
                  path="/dashboard/requests/:guid?"
                  element={<Requests />}
                />
                <Route path="/offer/:option?" element={<NewOffer />} />
                <Route path="/request/:option?" element={<NewSearch />} />
                <Route
                  path="/dashboard/message-center"
                  element={<MessageCenter />}
                />
              </Route>
            </Routes>
          </Router>
        </SearchContext.Provider>
      </PushProvider>
    </SearchFilterContext.Provider>
  );
};

export default App;
