import React from 'react';

const Loader = ({ inline = false }) => {
  return (
    <div className={`Loader-wrapper ${inline ? 'inline' : ''}`}>
      <div className="Loader" />
    </div>
  );
};

export default Loader;
