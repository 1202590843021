import * as signalR from '@microsoft/signalr';
import { useMemo, useReducer } from 'react';
import useDerivative from './useDerivative';

const initialState = {
  connectionStatus: 'Connecting...',
  messageHistory: [],
  lastMessage: null,
  error: null,
};

const SignalRStates = {
  ConnectionStatus: 'connectionStatus',
  MessageHistory: 'messageHistory',
  LastMessage: 'lastMessage',
  Error: 'error',
};

const reducer = (state, action) => {
  switch (action.type) {
    case SignalRStates.ConnectionStatus:
      return { ...state, connectionStatus: action.payload };
    case SignalRStates.MessageHistory:
      return { ...state, messageHistory: action.payload };
    case SignalRStates.LastMessage:
      return { ...state, lastMessage: action.payload };
    case SignalRStates.Error:
      return { ...state, error: action.payload };
    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};

const useSignalR = (url, userGuid) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { achievements } = useDerivative();
  useMemo(() => {
    if (achievements && url && userGuid) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .configureLogging(
          process.env.REACT_APP_ENV === 'development'
            ? signalR.LogLevel.Debug
            : signalR.LogLevel.None,
        ) // Set the log level to the desired level
        .build();

      connection
        .start()
        .then(() => {
          connection
            .invoke('RegisterUserGuid', userGuid)
            .then(() => {
              dispatch({
                type: SignalRStates.ConnectionStatus,
                payload: 'Connected',
              });
            })
            .catch((err) => {
              dispatch({
                type: SignalRStates.ConnectionStatus,
                payload: `Connection failed: ${err.message}`,
              });
              dispatch({ type: SignalRStates.Error, payload: err });
            });
        })
        .catch((err) => {
          dispatch({
            type: SignalRStates.ConnectionStatus,
            payload: 'Connection failed',
          });
          dispatch({ type: SignalRStates.Error, payload: err });
        });

      connection.on('PointsEarned', (UserGuid, Points, ActionName) => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('PointsEarned', UserGuid, Points, ActionName);
        }
        dispatch({
          type: SignalRStates.LastMessage,
          payload: [Points, ActionName],
        });
        dispatch({
          type: SignalRStates.MessageHistory,
          payload: (prevHistory) => [...prevHistory, Points],
        });
      });
    }
  }, [url, userGuid, achievements]);

  return state;
};

export default useSignalR;
