import { useEffect, useMemo, useState } from 'react';

export function googleGeocodeLatLng(lat, lng) {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      {
        location: { lat, lng },
      },
      (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve({
            address: results[0].formatted_address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          return;
        }
        reject(status);
      },
    );
  });
}

export function googleGeocodeAddress(address) {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      {
        address,
      },
      (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve({
            address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          return;
        }
        reject(status);
      },
    );
  });
}

const useGoogleGeocoder = () => {
  const googleService = useMemo(() => {
    return {
      geocoder: new google.maps.Geocoder(),
    };
  }, []);
  const [address, setAddress] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (address.trim()) {
      setLoading(true);
      googleService.geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === 'OK') {
            setResult(results);
            setLoading(false);
            return;
          }
          setError(status);
          setLoading(false);
        },
      );
    } else {
      setResult(null);
    }
  }, [address, googleService.geocoder]);

  return { result, loading, error, updateAddress: setAddress };
};

export default useGoogleGeocoder;
